import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Form } from '@socialbrothers/components/Containers';
import { Yup } from '@socialbrothers/utils';

import { AcousticValuesRepeater } from '@Components/inputs';
import { acousticValuesSchema } from '@Constants/schemas';
import { useListInputOptions } from '@Hooks/useListInputOptions';
import { CategoryService } from '@Services/Category';
import { SurfaceService } from '@Services/Surface';
import { APIDataTransformer, FormDataTransformer } from '@Utils/AcousticUtils';

export const surfaceSchema = acousticValuesSchema('acousticValues').shape({
  name: Yup.string().required(),
  categoryIds: Yup.array().of(Yup.string()),
});

export const SurfaceForm = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const categories = useListInputOptions(CategoryService);

  return (
    <Form.Resource
      service={SurfaceService}
      label={t('SURFACE.SINGLE')}
      id={id}
      preSubmitFormatter={FormDataTransformer}
      afterFetchFormatter={APIDataTransformer}
      validationSchema={surfaceSchema}>
      <Form.Layout.Field translationKey="SURFACE.LABELS.NAME">
        <Form.Input.Text name="name" placeholder={t('SURFACE.PLACEHOLDERS.NAME')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="SURFACE.LABELS.CATEGORY">
        <Form.Input.Select
          isMulti
          options={categories}
          name="categoryIds"
          placeholder={t('SURFACE.PLACEHOLDERS.CATEGORY')}
        />
      </Form.Layout.Field>

      <AcousticValuesRepeater
        entryName={t('SURFACE.LABELS.ACOUSTICVALUES')}
        source="acousticValues"
        label={t('SURFACE.LABELS.ACOUSTICVALUES')}
      />
    </Form.Resource>
  );
};
