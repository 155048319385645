import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Button, Card } from '@socialbrothers/components/UI';
import { Color } from '@socialbrothers/constants';

import { CategoryTable } from '@Components/tables';

export const CategoryOverviewPage = () => {
  const { t } = useTranslation();

  const buttons = (
    <Button icon="plus" to="/categories/create" color={Color.SECONDARY}>
      {t('GLOBAL.CREATE_MODEL', { name: t('CATEGORY.SINGLE') })}
    </Button>
  );

  return (
    <Page title={t('CATEGORY.PLURAL')} buttons={buttons}>
      <Card title={t('CATEGORY.PLURAL')}>
        <CategoryTable />
      </Card>
    </Page>
  );
};
