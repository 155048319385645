import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Button, Card } from '@socialbrothers/components/UI';
import { Color } from '@socialbrothers/constants';

import { GroupTable } from '@Components/tables';

export const GroupOverviewPage = () => {
  const { t } = useTranslation();

  const buttons = (
    <Button icon="plus" to="/groups/create" color={Color.SECONDARY}>
      {t('GLOBAL.CREATE_MODEL', { name: t('GROUP.SINGLE') })}
    </Button>
  );

  return (
    <Page title={t('GROUP.PLURAL')} buttons={buttons}>
      <Card title={t('GROUP.PLURAL')}>
        <GroupTable />
      </Card>
    </Page>
  );
};
