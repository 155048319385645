import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { CompanyService, ICompany } from '@Services/Company';

import styles from './CompanyTable.module.scss';
import { CompanyTableProps } from './CompanyTable.props';

export const CompanyTable = ({ className, defaultFilters }: CompanyTableProps) => {
  const { t } = useTranslation();

  return (
    <Table.Resource<ICompany>
      service={CompanyService}
      defaultFilters={defaultFilters}
      searchable
      className={cn(styles.CompanyTable, className)}>
      <Table.Field.Text sortable source="name" label={t('COMPANY.LABELS.NAME')} />

      <Table.Field.Date source="createdAt" sortable label={t('GLOBAL.LABELS.CREATEDAT')} />

      <Table.Field.ResourceAction
        deleteConfig={(record: ICompany) => ({
          name: record.name,
        })}
        editConfig={(record: ICompany) => ({
          link: `/companies/${record.id}`,
        })}
      />
    </Table.Resource>
  );
};
