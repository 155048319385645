import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { IRoomType, RoomTypeService } from '@Services/RoomType';

import styles from './RoomTypeTable.module.scss';
import { RoomTypeTableProps } from './RoomTypeTable.props';

export const RoomTypeTable = ({ defaultFilters, className }: RoomTypeTableProps) => {
  const { t } = useTranslation();

  return (
    <Table.Resource<IRoomType>
      service={RoomTypeService}
      searchable
      defaultFilters={defaultFilters}
      className={cn(styles.RoomTypeTable, className)}>
      <Table.Field.Text source="name" label={t('ROOMTYPE.LABELS.NAME')} sortable filterable />

      <Table.Field.Text
        source="categories"
        label={t('ROOMTYPE.LABELS.CATEGORIES')}
        sortable
        filterable
      />

      <Table.Field.ResourceAction
        deleteConfig={(record: IRoomType) => ({
          name: record.id,
        })}
        editConfig={(record: IRoomType) => ({
          link: `/roomtypes/${record.id}`,
        })}
      />
    </Table.Resource>
  );
};
