import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Form } from '@socialbrothers/components/Containers';
import { Yup } from '@socialbrothers/utils';

import { useListInputOptions } from '@Hooks/useListInputOptions';
import { CompanyService } from '@Services/Company';
import { UserService } from '@Services/User';

export const userSchema = Yup.object({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().email().required(),
  companyId: Yup.string().required(),
  roles: Yup.string().required(),
  language: Yup.string().required(),
});

export const UserForm = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const companyOptions = useListInputOptions(CompanyService);

  return (
    <Form.Resource
      service={UserService}
      label={t('USER.SINGLE')}
      validationSchema={userSchema}
      id={id}>
      <Form.Layout.Field translationKey="USER.LABELS.FIRSTNAME">
        <Form.Input.Text name="firstName" placeholder={t('USER.PLACEHOLDERS.FIRSTNAME')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="USER.LABELS.LASTNAME">
        <Form.Input.Text name="lastName" placeholder={t('USER.PLACEHOLDERS.LASTNAME')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="USER.LABELS.EMAIL">
        <Form.Input.Text name="email" placeholder={t('USER.PLACEHOLDERS.EMAIL')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="USER.LABELS.ROLES">
        <Form.Input.Select
          name="roles"
          placeholder={t('USER.PLACEHOLDERS.ROLES')}
          options={[
            { value: 'ADMIN', key: 'ADMIN' }, // TODO: implement better way when other pr is merged
            { value: 'ADVISOR', key: 'ADVISOR' },
            { value: 'DEALER', key: 'DEALER' },
          ]}
        />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="USER.LABELS.COMPANY">
        <Form.Input.Select
          options={companyOptions}
          name="companyId"
          placeholder={t('USER.PLACEHOLDERS.COMPANY')}
        />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="USER.LABELS.LANGUAGE">
        <Form.Input.Select
          name="language"
          placeholder={t('USER.PLACEHOLDERS.LANGUAGE')}
          options={[
            { value: 'NL', key: 'NL' }, // TODO: implement better way when other pr is merged
            { value: 'EN', key: 'EN' },
          ]}
        />
      </Form.Layout.Field>
    </Form.Resource>
  );
};
