import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { CategoryService, ICategory } from '@Services/Category';

import styles from './CategoryTable.module.scss';
import { CategoryTableProps } from './CategoryTable.props';

export const CategoryTable = ({ className, defaultFilters }: CategoryTableProps) => {
  const { t } = useTranslation();

  return (
    <Table.Resource<ICategory>
      service={CategoryService}
      searchable
      defaultFilters={defaultFilters}
      className={cn(styles.CategoryTable, className)}>
      <Table.Field.Text sortable source="name" label={t('CATEGORY.LABELS.NAME')} />

      <Table.Field.Text
        sortable
        filterable
        source="group.name"
        label={t('CATEGORY.LABELS.GROUP')}
      />

      <Table.Field.Date sortable source="createdAt" label={t('GLOBAL.LABELS.UPDATEDAT')} />

      <Table.Field.ResourceAction
        deleteConfig={(record: ICategory) => ({
          name: record.name,
        })}
        editConfig={(record: ICategory) => ({
          link: `/categories/${record.id}`,
        })}
      />
    </Table.Resource>
  );
};
