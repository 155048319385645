import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { IUser, UserService } from '@Services/User';

import styles from './UserTable.module.scss';
import { UserTableProps } from './UserTable.props';

export const UserTable = ({ defaultFilters, className }: UserTableProps) => {
  const { t } = useTranslation();

  return (
    <Table.Resource<IUser>
      service={UserService}
      className={cn(styles.UserTable, className)}
      defaultFilters={defaultFilters}>
      <Table.Field.Text source="firstName" label={t('USER.LABELS.FIRSTNAME')} />

      <Table.Field.Text source="lastName" label={t('USER.LABELS.LASTNAME')} />

      <Table.Field.Text source="email" label={t('USER.LABELS.EMAIL')} />

      <Table.Field.Function
        source="roles"
        label={t('USER.LABELS.ROLES')}
        render={(user: IUser) => {
          return (
            <>
              {user.roles.map((role) => (
                <>{role.name}</>
              ))}
            </>
          );
        }}
      />

      <Table.Field.Date sortable source="createdAt" label={t('GLOBAL.LABELS.CREATEDAT')} />

      <Table.Field.ResourceAction
        deleteConfig={(record: IUser) => ({
          name: record.id,
        })}
        editConfig={(record: IUser) => ({
          link: `/users/${record.id}`,
        })}
      />
    </Table.Resource>
  );
};
