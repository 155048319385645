import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Form } from '@socialbrothers/components/Containers';
import { Yup } from '@socialbrothers/utils';

import { GroupService } from '@Services/Group';

export const groupSchema = Yup.object({
  name: Yup.string().required(),
  description: Yup.string().required(),
});

export const GroupForm = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  return (
    <Form.Resource
      validationSchema={groupSchema}
      service={GroupService}
      label={t('GROUP.SINGLE')}
      id={id}>
      <Form.Layout.Field translationKey="GROUP.LABELS.NAME">
        <Form.Input.Text name="name" placeholder={t('GROUP.PLACEHOLDERS.NAME')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="GROUP.LABELS.DESCRIPTION">
        <Form.Input.Multiline
          name="description"
          placeholder={t('GROUP.PLACEHOLDERS.DESCRIPTION')}
        />
      </Form.Layout.Field>
    </Form.Resource>
  );
};
