import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { IRoom, RoomService } from '@Services/Room';

import styles from './RoomTable.module.scss';
import { RoomTableProps } from './RoomTable.props';

export const RoomTable = ({ defaultFilters, className }: RoomTableProps) => {
  const { t } = useTranslation();

  return (
    <Table.Resource<IRoom>
      service={RoomService}
      defaultFilters={defaultFilters}
      className={cn(styles.RoomTable, className)}>
      <Table.Field.Text source="roomType.name" label={t('ROOM.LABELS.ROOMTYPE')} sortable />

      <Table.Field.Number source="surface" label={t('ROOM.LABELS.SURFACE')} sortable />

      <Table.Field.Number source="minHeight" label={t('ROOM.LABELS.MINHEIGHT')} sortable />

      <Table.Field.Number source="maxHeight" label={t('ROOM.LABELS.MAXHEIGHT')} sortable />

      <Table.Field.Number source="avgHeight" label={t('ROOM.LABELS.AVGHEIGHT')} sortable />

      <Table.Field.Number source="volume" label={t('ROOM.LABELS.VOLUME')} sortable />

      <Table.Field.Boolean source="isHighRoom" label={t('ROOM.LABELS.ISHIGHROOM')} sortable />

      <Table.Field.ResourceAction
        deleteConfig={(record: IRoom) => ({
          name: record.id,
        })}
        editConfig={(record: IRoom) => ({
          link: `/rooms/${record.id}`,
        })}
      />
    </Table.Resource>
  );
};
