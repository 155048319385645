import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { IProduct, ProductService } from '@Services/Product';

import styles from './ProductTable.module.scss';
import { ProductTableProps } from './ProductTable.props';

export const ProductTable = ({ className, defaultFilters }: ProductTableProps) => {
  const { t } = useTranslation();

  return (
    <Table.Resource<IProduct>
      service={ProductService}
      searchable
      defaultFilters={defaultFilters}
      className={cn(styles.ProductTable, className)}>
      <Table.Field.Text sortable source="name" label={t('PRODUCT.LABELS.NAME')} />

      <Table.Field.Text sortable filterable source="brand" label={t('PRODUCT.LABELS.BRAND')} />

      <Table.Field.Date sortable source="createdAt" label={t('GLOBAL.LABELS.CREATEDAT')} />

      <Table.Field.ResourceAction
        deleteConfig={(record: IProduct) => ({
          name: record.name,
        })}
        editConfig={(record: IProduct) => ({
          link: `/products/${record.id}`,
        })}
      />
    </Table.Resource>
  );
};
