import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';
import { useResource } from '@socialbrothers/hooks';

import { BlogForm } from '@Components/forms';
import { BlogService, IBlog } from '@Services/Blog';

export const BlogDetailPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { data } = useResource<IBlog>(BlogService, id);

  const label = data?.name ? data.name : t('BLOG.SINGLE');

  return (
    <Page title={label}>
      <Card title={label}>
        <BlogForm />
      </Card>
    </Page>
  );
};
