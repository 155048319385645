import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { IProject, ProjectService } from '@Services/Project';

import styles from './ProjectTable.module.scss';
import { ProjectTableProps } from './ProjectTable.props';

export const ProjectTable = ({ className, defaultFilters }: ProjectTableProps) => {
  const { t } = useTranslation();

  return (
    <Table.Resource<IProject>
      service={ProjectService}
      defaultFilters={defaultFilters}
      searchable
      className={cn(styles.ProjectTable, className)}>
      <Table.Field.Text sortable source="name" label={t('PROJECT.LABELS.NAME')} />

      <Table.Field.Text
        sortable
        filterable
        source="customer.name"
        label={t('PROJECT.LABELS.CUSTOMER')}
      />

      <Table.Field.Text
        sortable
        filterable
        source="category.name"
        label={t('PROJECT.LABELS.CATEGORY')}
      />

      <Table.Field.Function
        render={({ advisor }) => `${advisor.firstName} ${advisor.lastName}`}
        sortable
        filterable
        source="advisor.fullName"
        label={t('PROJECT.LABELS.ADVISOR')}
      />

      <Table.Field.Text sortable filterable source="status" label={t('PROJECT.LABELS.STATUS')} />

      <Table.Field.Date sortable source="updatedAt" label={t('GLOBAL.LABELS.UPDATEDAT')} />

      <Table.Field.Date sortable source="createdAt" label={t('GLOBAL.LABELS.CREATEDAT')} />

      <Table.Field.ResourceAction
        deleteConfig={(record: IProject) => ({
          name: record.name,
        })}
        editConfig={(record: IProject) => ({
          link: `/projects/${record.id}`,
        })}
      />
    </Table.Resource>
  );
};
