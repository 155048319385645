import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';
import { useResource } from '@socialbrothers/hooks';

import { ProductForm } from '@Components/forms';
import { IProduct, ProductService } from '@Services/Product';

export const ProductDetailPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { data } = useResource<IProduct>(ProductService, id);

  const label = data?.name ? data.name : t('PRODUCT.SINGLE');

  return (
    <Page title={label}>
      <Card title={label}>
        <ProductForm />
      </Card>
    </Page>
  );
};
