import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { CustomerService, ICustomer } from '@Services/Customer';

import styles from './CustomerTable.module.scss';
import { CustomerTableProps } from './CustomerTable.props';

export const CustomerTable = ({ defaultFilters, className }: CustomerTableProps) => {
  const { t } = useTranslation();

  return (
    <Table.Resource<ICustomer>
      service={CustomerService}
      defaultFilters={defaultFilters}
      className={cn(styles.CustomerTable, className)}>
      <Table.Field.Text source="name" label={t('CUSTOMER.LABELS.NAME')} />

      <Table.Field.Text source="number" label={t('CUSTOMER.LABELS.NUMBER')} />

      <Table.Field.Text
        source="accountManager.fullName"
        label={t('CUSTOMER.LABELS.ACCOUNTMANAGER')}
      />

      <Table.Field.Text source="email" label={t('CUSTOMER.LABELS.EMAIL')} />

      <Table.Field.Text source="phone" label={t('CUSTOMER.LABELS.PHONE')} />

      <Table.Field.ResourceAction
        deleteConfig={(record: ICustomer) => ({
          name: record.name,
        })}
        editConfig={(record: ICustomer) => ({
          link: `/customers/${record.id}`,
        })}
      />
    </Table.Resource>
  );
};
