import {
  AcousticFormData,
  AVCategories,
  AVKeys,
  FormDataEntry,
  IAcousticValue,
  RawAcousticFormDataEntry,
} from '@Constants/interfaces';
import { rawAcousticValueSchema } from '@Constants/schemas';

/**
 * Transforms data returned from react-hook-form into data used by the API.
 */
export const FormDataTransformer = ({ _acousticValues, ...data }: AcousticFormData) => {
  if (typeof _acousticValues === 'undefined') {
    return data;
  }

  const acousticValues = Object.fromEntries(
    Object.entries(_acousticValues).map(([source, fields]) => [
      source,
      fields.flatMap(({ category, ...values }) =>
        Object.entries(values).map(([key, value]) => ({ value, key, category })),
      ),
    ]),
  );

  return { ...acousticValues, ...data };
};

/**
 * Checks wether the given data mathes the {@link rawAcousticValueSchema}.
 *
 * @param dataEntry - An entry which possibly contains AcousticValues.
 *
 * @returns A boolean value that indicates whether dataEntry contains valid
 * AcousticValues.
 */
const isAcousticValues = (dataEntry: FormDataEntry): dataEntry is RawAcousticFormDataEntry =>
  rawAcousticValueSchema.isValidSync(dataEntry[1]);

/**
 * Creates an array with entries grouped by their categories.
 *
 * It reduces the array of acousticValues into a Map instance where it groups
 * the acousticValues with a common category into the same array. This Map is
 * transformed into an array of entries.
 */
const groupValuesByCategory = (
  acousticValues: IAcousticValue[],
): [AVCategories, IAcousticValue[]][] => {
  const categoryValuesMap = acousticValues.reduce(
    (storage, item) => storage.set(item.category, [...(storage.get(item.category) ?? []), item]),
    new Map<AVCategories, IAcousticValue[]>(),
  );
  return Array.from(categoryValuesMap);
};

/**
 * Transforms key/value entries into data AcousticValuesRepeater can read.
 *
 * The first step is mapping all values into an array of entries. These entries
 * have a key matching a member of {@link AcousticValueKeys}, and their value is
 * converted into a string. It then coverts these entries into an Object.
 */
const keyValueTransformer = ([category, acousticValues]: [AVCategories, IAcousticValue[]]) => {
  const acousticEntries = acousticValues.map(({ key, value }) => [key, String(value)]) as [
    AVKeys,
    string,
  ][];

  const transformedAcousticValues = Object.fromEntries(acousticEntries);

  return {
    category,
    ...transformedAcousticValues,
  };
};

/**
 * Transform the values of a data entry.
 *
 * It first groups the acousticValues using {@link groupValuesByCategory},
 * which it then maps using {@link keyValueTransformer}.
 */
const acousticValuesDataEntryTransformer = ([source, acousticValues]: RawAcousticFormDataEntry) => {
  const groupedValues = groupValuesByCategory(acousticValues);

  const transformedValues = groupedValues.map(keyValueTransformer);

  return [source, transformedValues];
};

/**
 * Transform API data into AcousticValuesRepeater data.
 *
 * @param data - API data to transform.
 * @returns The transformed acousticValues and the rest of the data.
 */
export const APIDataTransformer = (data: FormData): AcousticFormData => {
  if (typeof data !== 'object' || data === null) {
    return data;
  }

  const dataEntries = Object.entries(data);
  const acousticValuesDataEntries = dataEntries.filter(isAcousticValues);
  const transformedDataEntries = acousticValuesDataEntries.map(acousticValuesDataEntryTransformer);

  const transformedAcousticValues = Object.fromEntries(transformedDataEntries);

  return { _acousticValues: transformedAcousticValues, ...data };
};
