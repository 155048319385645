import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Breadcrumbs, Footer, Header, Sidebar } from '@socialbrothers/components/Layout';
import { RoutesLink } from '@socialbrothers/constants';
import { useBreadcrumbs } from '@socialbrothers/hooks';

import { useAdmin } from '@Hooks/useAdmin';
import { adminRoutes, dealerRoutes } from '@Routes/routes';

import styles from './Page.module.scss';
import { PageProps } from './Page.props';

const Page = ({ title, breadcrumbs = true, buttons, children }: PageProps) => {
  const { t } = useTranslation();
  const hasCustomBreadcrumbs = typeof breadcrumbs === 'object';

  const isAdmin = useAdmin();

  let items = useBreadcrumbs(isAdmin ? adminRoutes : dealerRoutes, title);
  items = hasCustomBreadcrumbs ? (breadcrumbs as RoutesLink[]) : items;

  useEffect(() => {
    document.title = t('GLOBAL.SITE_TITLE', {
      name: title,
    });
  }, [t, title]);

  return (
    <div className={styles.Page}>
      <Sidebar />

      <div className={styles.Body}>
        <Header />

        <div className={styles.Header}>
          <div className={styles.Header__Content}>
            {title && <h1 className={styles.Title}>{title}</h1>}
            {breadcrumbs && <Breadcrumbs items={items} />}
          </div>

          {buttons && <div className={styles.Header__Buttons}>{buttons}</div>}
        </div>

        <div className={styles.Content}>{children}</div>

        <Footer />
      </div>
    </div>
  );
};

export default observer(Page);
