import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Form } from '@socialbrothers/components/Containers';
import { Yup } from '@socialbrothers/utils';

import { useListInputOptions } from '@Hooks/useListInputOptions';
import { BlogService } from '@Services/Blog';
import { CategoryService } from '@Services/Category';
import { CustomerService } from '@Services/Customer';

export const blogSchema = Yup.object({
  name: Yup.string().required(),
  description: Yup.string().required(),
  categoryId: Yup.string().required(),
  customerId: Yup.string().required(),
  image: Yup.mixed().required(),
});

export const BlogForm = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const categoryOptions = useListInputOptions(CategoryService);
  const customerOptions = useListInputOptions(CustomerService);

  return (
    <Form.Resource
      service={BlogService}
      validationSchema={blogSchema}
      label={t('BLOG.SINGLE')}
      id={id}>
      <Form.Layout.Field translationKey="BLOG.LABELS.NAME">
        <Form.Input.Text name="name" placeholder={t('BLOG.PLACEHOLDERS.NAME')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="BLOG.LABELS.DESCRIPTION">
        <Form.Input.Multiline
          name="description"
          placeholder={t('GROUP.PLACEHOLDERS.DESCRIPTION')}
        />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="BLOG.LABELS.CATEGORY">
        <Form.Input.Select
          options={categoryOptions}
          name="categoryId"
          placeholder={t('BLOG.PLACEHOLDERS.CATEGORY')}
        />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="BLOG.LABELS.CUSTOMER">
        <Form.Input.Select
          options={customerOptions}
          name="customerId"
          placeholder={t('BLOG.PLACEHOLDERS.CUSTOMER')}
        />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="BLOG.LABELS.IMAGE">
        <Form.Input.File thumbnail accept="image/jpeg, image/png" name="image" />
      </Form.Layout.Field>
    </Form.Resource>
  );
};
