import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { BlogService, IBlog } from '@Services/Blog';

import styles from './BlogTable.module.scss';
import { BlogTableProps } from './BlogTable.props';

export const BlogTable = ({ className, defaultFilters }: BlogTableProps) => {
  const { t } = useTranslation();

  return (
    <Table.Resource<IBlog>
      service={BlogService}
      searchable
      defaultFilters={defaultFilters}
      className={cn(styles.BlogTable, className)}>
      <Table.Field.Text sortable source="name" label={t('BLOG.LABELS.NAME')} />

      <Table.Field.Text
        sortable
        filterable
        source="customer.name"
        label={t('BLOG.LABELS.CUSTOMER')}
      />

      <Table.Field.Text
        sortable
        filterable
        source="category.name"
        label={t('BLOG.LABELS.CATEGORY')}
      />

      <Table.Field.Date sortable source="createdAt" label={t('GLOBAL.LABELS.CREATEDAT')} />

      <Table.Field.Date sortable source="updatedAt" label={t('GLOBAL.LABELS.UPDATEDAT')} />

      <Table.Field.ResourceAction
        deleteConfig={(record: IBlog) => ({
          name: record.name,
        })}
        editConfig={(record: IBlog) => ({
          link: `/blogs/${record.id}`,
        })}
      />
    </Table.Resource>
  );
};
