import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';
import { useResource } from '@socialbrothers/hooks';

import { TestimonialForm } from '@Components/forms';
import { ITestimonial, TestimonialService } from '@Services/Testimonial';

export const TestimonialDetailPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { data } = useResource<ITestimonial>(TestimonialService, id);

  const label = data?.description ? data.description : t('TESTIMONIAL.SINGLE');

  return (
    <Page title={label}>
      <Card title={label}>
        <TestimonialForm />
      </Card>
    </Page>
  );
};
