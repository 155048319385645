import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import styles from './SelectInput.module.scss';
import { SelectInputProps } from './SelectInput.props';

const SelectInput = ({
  name,
  options,
  disabled,
  selectFirstOption,
  placeholder,
  defaultValue,
  isMulti,
  onChange: handleChange,
}: SelectInputProps) => {
  const { t } = useTranslation();
  const { control, watch } = useFormContext();
  const value = watch(name);

  const changeHandler = useCallback(
    (handleValue: string | number) => !!handleChange && handleChange(handleValue),
    [handleChange],
  );

  const selectOptions = options.reduce<Array<{ [key: string]: any }>>((total, item) => {
    total.push({
      value: item.key,
      label: item.value,
      isDisabled: item.disabled,
    });

    return total;
  }, []);

  const getOptionByKey = (key: string) => {
    return selectOptions.find((item) => {
      return String(item.value) === String(key);
    });
  };

  const customStyles = {
    menuPortal: (base: any) => ({
      ...base,
      zIndex: 1001,
    }),
    control: (base: any) => ({
      ...base,
      paddingLeft: 8,
      height: 48,
      minHeight: 48,
    }),
  };

  const initialValue =
    getOptionByKey(defaultValue) ?? (selectFirstOption ? selectOptions[0] : null);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange }) => {
        return (
          <Select
            onChange={(newValue) => {
              const valueNew = Array.isArray(newValue)
                ? newValue.map((newValueItem) => newValueItem.value)
                : newValue.value;

              onChange(valueNew);
              changeHandler(valueNew);
            }}
            isMulti={isMulti}
            styles={customStyles}
            value={getOptionByKey(value)}
            menuPortalTarget={document.body}
            className={styles.SelectInput__Select}
            options={selectOptions}
            isDisabled={disabled}
            defaultValue={initialValue}
            placeholder={placeholder || t('FORMS.SELECT.PLACEHOLDER')}
          />
        );
      }}
    />
  );
};

export default SelectInput;
