import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { ITestimonial, TestimonialService } from '@Services/Testimonial';

import styles from './TestimonialTable.module.scss';
import { TestimonialTableProps } from './TestimonialTable.props';

export const TestimonialTable = ({ defaultFilters, className }: TestimonialTableProps) => {
  const { t } = useTranslation();

  return (
    <Table.Resource<ITestimonial>
      service={TestimonialService}
      className={cn(styles.TestimonialTable, className)}
      defaultFilters={defaultFilters}>
      <Table.Field.Text source="customer.name" label={t('TESTIMONIAL.LABELS.CUSTOMER')} />

      <Table.Field.Text source="description" label={t('TESTIMONIAL.LABELS.DESCRIPTION')} />

      <Table.Field.Text source="category.name" label={t('TESTIMONIAL.LABELS.CATEGORY')} />

      <Table.Field.Date source="createdAt" label={t('GLOBAL.LABELS.CREATEDAT')} />

      <Table.Field.ResourceAction
        deleteConfig={(record: ITestimonial) => ({
          name: record.description,
        })}
        editConfig={(record: ITestimonial) => ({
          link: `/testimonials/${record.id}`,
        })}
      />
    </Table.Resource>
  );
};
