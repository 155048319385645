import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { Form } from '@socialbrothers/components/Containers';
import { Button } from '@socialbrothers/components/UI';
import { ApiError } from '@socialbrothers/constants';
import { useTheme } from '@socialbrothers/hooks';

import { useStores } from '@Hooks/index';

import styles from './LoginForm.module.scss';
import { LoginFormProps, LoginFormValues } from './LoginForm.props';

const LoginForm = ({ ...props }: LoginFormProps) => {
  const { t } = useTranslation();
  const { authService } = useTheme();
  const [error, setError] = useState('');
  const { AuthStore } = useStores();

  const validationSchemaLogin = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
  });

  const handleLogin = async (values: LoginFormValues) => {
    try {
      await authService.login(values);

      AuthStore.setIsAuthenticated(true);
      setError('');
    } catch (e) {
      if (e.response?.data?.code === ApiError.INVALID_CREDENTIALS) {
        setError(t('VALIDATION.LOGIN.INCORRECT_CREDENTIALS'));
      } else {
        setError(t('VALIDATION.COMMON.UNKNOWN_ERROR'));
      }
    }
  };

  return (
    <Form.Base
      className={styles.LoginForm}
      onSubmit={handleLogin}
      validationSchema={validationSchemaLogin}
      {...props}>
      <Form.Layout.Field label={t('FORMS.COMMON_LABELS.EMAIL')}>
        <Form.Input.Text name="email" />
      </Form.Layout.Field>
      <Form.Layout.Field label="Wachtwoord">
        <Form.Input.Text type="password" name="password" />
      </Form.Layout.Field>

      {!!error && <div className={styles.Error}>{error}</div>}

      <div className={styles.Footer}>
        <Button type="submit">{t('AUTHENTICATION.LOGIN_PAGE.SUBMIT_LABEL')}</Button>
        <hr className={styles.Seperator} />
        <div className={styles.FooterLinks}>
          <Link to="forgot-password">{t('AUTHENTICATION.FORGOT_PASSWORD')}</Link>
        </div>
      </div>
    </Form.Base>
  );
};

export default LoginForm;
