import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';

export const DashboardPage = () => {
  const { t } = useTranslation();

  return (
    <Page breadcrumbs={false}>
      <Card title={t('DASHBOARD.TITLE')}></Card>
    </Page>
  );
};
