import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Form } from '@socialbrothers/components/Containers';
import { Yup } from '@socialbrothers/utils';

import { useListInputOptions } from '@Hooks/useListInputOptions';
import { CategoryService } from '@Services/Category';
import { GroupService } from '@Services/Group';

export const categorySchema = Yup.object({
  name: Yup.string().required(),
  groupId: Yup.string().required(),
  whitepaper: Yup.mixed().required(),
  image: Yup.mixed().required(),
});

export const CategoryForm = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const groupOptions = useListInputOptions(GroupService);

  return (
    <Form.Resource service={CategoryService} label={t('CATEGORY.SINGLE')} id={id}>
      <Form.Layout.Field translationKey="CATEGORY.LABELS.NAME">
        <Form.Input.Text name="name" placeholder={t('CATEGORY.PLACEHOLDERS.NAME')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="CATEGORY.LABELS.GROUP">
        <Form.Input.Select
          options={groupOptions}
          name="groupId"
          placeholder={t('CATEGORY.PLACEHOLDERS.GROUP')}
        />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="CATEGORY.LABELS.WHITEPAPER">
        <Form.Input.File accept="application/pdf" name="whitepaper" />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="CATEGORY.LABELS.IMAGE">
        <Form.Input.File thumbnail accept="image/jpeg, image/png" name="image" />
      </Form.Layout.Field>
    </Form.Resource>
  );
};
