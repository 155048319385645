import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Button, Card } from '@socialbrothers/components/UI';
import { Color } from '@socialbrothers/constants';

import { SurfaceTable } from '@Components/tables';

export const SurfaceOverviewPage = () => {
  const { t } = useTranslation();

  const buttons = (
    <Button icon="plus" to="/surfaces/create" color={Color.SECONDARY}>
      {t('GLOBAL.CREATE_MODEL', { name: t('SURFACE.SINGLE') })}
    </Button>
  );

  return (
    <Page title={t('SURFACE.PLURAL')} buttons={buttons}>
      <Card title={t('SURFACE.PLURAL')}>
        <SurfaceTable />
      </Card>
    </Page>
  );
};
