import { useMemo } from 'react';

import { BaseModel, ExtractFunction } from '@socialbrothers/constants';
import { useList } from '@socialbrothers/hooks';
import { ResourceService } from '@socialbrothers/services';
import { modelToOptions } from '@socialbrothers/utils';

export const useListInputOptions = <K extends BaseModel>(
  service: ResourceService<K>,
  value: string | ExtractFunction<K> = 'name',
) => {
  const { data } = useList(service, { perPage: 9999 });

  return useMemo(() => modelToOptions(data?.results ?? [], value), [data?.results, value]);
};
