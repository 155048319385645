import BLOG from './blog.json';
import CATEGORY from './category.json';
import COMPANY from './company.json';
import CUSTOMER from './customer.json';
import DASHBOARD from './dashboard.json';
import ENUM from './enum.json';
import FORMS from './forms.json';
import GLOBAL from './global.json';
import GROUP from './group.json';
import PRODUCT from './product.json';
import PROJECT from './project.json';
import ROOM from './room.json';
import ROOMTYPE from './roomtype.json';
import SURFACE from './surface.json';
import TABLE from './table.json';
import TESTIMONIAL from './testimonial.json';
import USER from './user.json';

const data = {
  translation: {
    BLOG,
    CATEGORY,
    COMPANY,
    CUSTOMER,
    DASHBOARD,
    ENUM,
    FORMS,
    GLOBAL,
    GROUP,
    PRODUCT,
    PROJECT,
    ROOM,
    ROOMTYPE,
    SURFACE,
    TABLE,
    TESTIMONIAL,
    USER,
  },
};

export default data;
