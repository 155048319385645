import { sum } from 'lodash';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Form } from '@socialbrothers/components/Containers';
import { Yup } from '@socialbrothers/utils';

import { AcousticValuesRepeater } from '@Components/inputs';
import { acousticValuesSchema } from '@Constants/schemas';
import { useListInputOptions } from '@Hooks/useListInputOptions';
import { CategoryService } from '@Services/Category';
import { RoomTypeService } from '@Services/RoomType';
import { APIDataTransformer, FormDataTransformer } from '@Utils/AcousticUtils';

import { RoomTypeFormValues } from './RoomTypeForm.props';

export const roomTypeSchema = acousticValuesSchema('acousticValues').shape({
  name: Yup.string().required(),
  categoryIds: Yup.array().of(Yup.string()),
  reverbPercentage: Yup.string().required(),
  frequenciesPercentage: Yup.string().required(),
  wallPanelsPercentage: Yup.string().required(),
  screensPercentage: Yup.string().required(),
  totalPercentage: Yup.number().equals([100]),
});

export const RoomTypeForm = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const [total, setTotal] = useState(100);

  const categoryOptions = useListInputOptions(CategoryService);

  const updateTotal = useCallback((values: RoomTypeFormValues) => {
    setTotal(
      sum([
        parseInt(values.reverbPercentage),
        parseInt(values.screensPercentage),
        parseInt(values.frequenciesPercentage),
        parseInt(values.wallPanelsPercentage),
      ]),
    );
  }, []);

  return (
    <Form.Resource
      service={RoomTypeService}
      label={t('ROOMTYPE.SINGLE')}
      id={id}
      preSubmitFormatter={FormDataTransformer}
      afterFetchFormatter={APIDataTransformer}
      validationSchema={roomTypeSchema}
      onChange={updateTotal}>
      <Form.Layout.Field translationKey="ROOMTYPE.LABELS.NAME">
        <Form.Input.Text name="name" placeholder={t('ROOMTYPE.PLACEHOLDERS.NAME')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="ROOMTYPE.LABELS.CATEGORIES">
        <Form.Input.Select
          isMulti
          options={categoryOptions}
          name="categoryIds"
          placeholder={t('ROOMTYPE.PLACEHOLDERS.CATEGORIES')}
        />
      </Form.Layout.Field>

      <AcousticValuesRepeater
        entryName={t('ROOMTYPE.LABELS.ACOUSTICVALUES')}
        source="acousticValues"
        label={t('ROOMTYPE.LABELS.ACOUSTICVALUES')}
      />

      <Form.Layout.Field translationKey="ROOMTYPE.LABELS.REVERBPERCENTAGE">
        <Form.Input.Number
          name="reverbPercentage"
          defaultValue={25}
          placeholder={t('ROOMTYPE.PLACEHOLDERS.REVERBPERCENTAGE')}
        />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="ROOMTYPE.LABELS.FREQUENCIESPERCENTAGE">
        <Form.Input.Number
          name="frequenciesPercentage"
          defaultValue={25}
          placeholder={t('ROOMTYPE.PLACEHOLDERS.FREQUENCIESPERCENTAGE')}
        />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="ROOMTYPE.LABELS.WALLPANELSPERCENTAGE">
        <Form.Input.Number
          name="wallPanelsPercentage"
          defaultValue={25}
          placeholder={t('ROOMTYPE.PLACEHOLDERS.WALLPANELSPERCENTAGE')}
        />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="ROOMTYPE.LABELS.SCREENSPERCENTAGE">
        <Form.Input.Number
          name="screensPercentage"
          defaultValue={25}
          placeholder={t('ROOMTYPE.PLACEHOLDERS.SCREENSPERCENTAGE')}
        />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="ROOMTYPE.LABELS.TOTALPERCENTAGE">
        <Form.Input.Number disabled defaultValue={100} value={total} name="totalPercentage" />
      </Form.Layout.Field>
    </Form.Resource>
  );
};
