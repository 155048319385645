import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Form } from '@socialbrothers/components/Containers';
import { Yup } from '@socialbrothers/utils';

import { useListInputOptions } from '@Hooks/useListInputOptions';
import { CustomerService } from '@Services/Customer';
import { UserService } from '@Services/User';

export const customerSchema = Yup.object({
  name: Yup.string().required(),
  number: Yup.string().required(),
  accountManagerId: Yup.string().required(),
  email: Yup.string().email().required(),
  phone: Yup.string().required(),
});

export const CustomerForm = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const userOptions = useListInputOptions(UserService, 'fullName');

  return (
    <Form.Resource
      service={CustomerService}
      label={t('CUSTOMER.SINGLE')}
      validationSchema={customerSchema}
      id={id}>
      <Form.Layout.Field translationKey="CUSTOMER.LABELS.NAME">
        <Form.Input.Text name="name" placeholder={t('CUSTOMER.PLACEHOLDERS.NAME')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="CUSTOMER.LABELS.NUMBER">
        <Form.Input.Text name="number" placeholder={t('CUSTOMER.PLACEHOLDERS.NUMBER')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="CUSTOMER.LABELS.ACCOUNTMANAGER">
        <Form.Input.Select
          options={userOptions}
          name="accountManagerId"
          placeholder={t('CUSTOMER.PLACEHOLDERS.ACCOUNTMANAGER')}
        />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="CUSTOMER.LABELS.EMAIL">
        <Form.Input.Text name="email" placeholder={t('CUSTOMER.PLACEHOLDERS.EMAIL')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="CUSTOMER.LABELS.PHONE">
        <Form.Input.Text name="phone" placeholder={t('CUSTOMER.PLACEHOLDERS.PHONE')} />
      </Form.Layout.Field>
    </Form.Resource>
  );
};
