import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Button, Card } from '@socialbrothers/components/UI';
import { Color } from '@socialbrothers/constants';

import { ProjectTable } from '@Components/tables';

export const ProjectOverviewPage = () => {
  const { t } = useTranslation();

  const buttons = (
    <Button icon="plus" to="/projects/create" color={Color.SECONDARY}>
      {t('GLOBAL.CREATE_MODEL', { name: t('PROJECT.SINGLE') })}
    </Button>
  );

  return (
    <Page title={t('PROJECT.PLURAL')} buttons={buttons}>
      <Card title={t('PROJECT.PLURAL')}>
        <ProjectTable />
      </Card>
    </Page>
  );
};
