import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { ISurface, SurfaceService } from '@Services/Surface';

import styles from './SurfaceTable.module.scss';
import { SurfaceTableProps } from './SurfaceTable.props';

export const SurfaceTable = ({ defaultFilters, className }: SurfaceTableProps) => {
  const { t } = useTranslation();

  return (
    <Table.Resource<ISurface>
      service={SurfaceService}
      defaultFilters={defaultFilters}
      searchable
      className={cn(styles.SurfaceTable, className)}>
      <Table.Field.Text source="name" label={t('SURFACE.LABELS.NAME')} sortable filterable />

      <Table.Field.ResourceAction
        deleteConfig={(record: ISurface) => ({
          name: record.name,
        })}
        editConfig={(record: ISurface) => ({
          link: `/surfaces/${record.id}`,
        })}
      />
    </Table.Resource>
  );
};
