import { ResourceService } from '@socialbrothers/services';

import { AppService } from '@Services/index';
import { ITestimonial } from '@Services/Testimonial';

export class TestimonialServiceImplementation extends ResourceService<ITestimonial> {}

export const TestimonialService = new TestimonialServiceImplementation(
  AppService,
  '/admin/testimonials',
);
