import axios, { AxiosResponse } from 'axios';

import { Api, LoginResponse } from '@socialbrothers/constants';
import { Storage } from '@socialbrothers/helpers';
import { BaseService } from '@socialbrothers/services';

import RootStore from '@Stores/RootStore';

class AppServiceImplementation extends BaseService {
  private isRefreshing = false;

  private failedQueue: any[] = [];

  constructor(baseUrl: string) {
    super(baseUrl);

    const token = Storage.getAccessToken(Api.MAIN);

    if (token) {
      this.setAccessToken(token);
    }

    this.api.interceptors.response.use(
      (response) => response,
      (err) => {
        const originalRequest = err.config;

        if (err.response.status === 401 && !originalRequest._retry) {
          // Fill queue till token is successfully refreshed...
          if (this.isRefreshing) {
            return new Promise((resolve, reject) => {
              this.failedQueue.push({ resolve, reject });
            })
              .then((newToken) => {
                originalRequest.headers.Authorization = 'Bearer ' + newToken;
                return this.api.request(originalRequest);
              })
              .catch((e) => {
                return Promise.reject(e);
              });
          }

          originalRequest._retry = true;
          this.isRefreshing = true;

          // Try to refresh the accessToken with the deprecated accessToken...
          return new Promise((resolve, reject) => {
            this.refreshToken()
              .then((response) => {
                this.setToken(response.data);

                originalRequest.headers.Authorization = 'Bearer ' + response.data.accessToken;
                this.processQueue(null, response.data.accessToken);
                resolve(this.api.request(originalRequest));
              })
              .catch((e) => {
                this.deleteToken();

                this.processQueue(e, '');
                reject(e);
              })
              .then(() => {
                this.isRefreshing = false;
              });
          });
        }

        return Promise.reject(err);
      },
    );
  }

  private setToken = (token: LoginResponse) => {
    Storage.setAccessToken(Api.MAIN, {
      ...token,
    });

    this.setAccessToken(token);
    RootStore.AuthStore.setIsAuthenticated(true);
  };

  private deleteToken = () => {
    Storage.removeAccessToken(Api.MAIN);
    this.setAccessToken();

    RootStore.AuthStore.setIsAuthenticated(false);
  };

  private refreshToken = (): Promise<AxiosResponse<LoginResponse>> => {
    const token = Storage.getAccessToken(Api.MAIN);

    return axios.post<LoginResponse>(
      `${process.env.REACT_APP_API_URL}/auth/refresh`,
      {
        refreshToken: token?.refreshToken,
      },
      {
        headers: {
          Authorization: `Bearer ${token?.accessToken}`,
        },
      },
    );
  };

  private processQueue = (error: any, token: string = '') => {
    this.failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    this.failedQueue = [];
  };
}

export const AppService = new AppServiceImplementation(process.env.REACT_APP_API_URL as string);
