import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';
import { useResource } from '@socialbrothers/hooks';

import { UserForm } from '@Components/forms';
import { IUser, UserService } from '@Services/User';

export const UserDetailPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { data } = useResource<IUser>(UserService, id);

  const label = data?.fullName ? data.fullName : t('USER.SINGLE');

  return (
    <Page title={label}>
      <Card title={label}>
        <UserForm />
      </Card>
    </Page>
  );
};
