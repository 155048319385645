import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Form } from '@socialbrothers/components/Containers';
import { Yup } from '@socialbrothers/utils';

import { useListInputOptions } from '@Hooks/useListInputOptions';
import { ProjectService } from '@Services/Project';
import { RoomService } from '@Services/Room';
import { RoomTypeService } from '@Services/RoomType';

export const roomSchema = Yup.object({
  surface: Yup.number().required(),
  minHeight: Yup.number().required(),
  maxHeight: Yup.number().required(),
  avgHeight: Yup.number().required(),
  roomTypeId: Yup.string().required(),
  projectId: Yup.string().required(),
});

export const RoomForm = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const roomTypeOptions = useListInputOptions(RoomTypeService);
  const projectOptions = useListInputOptions(ProjectService);

  return (
    <Form.Resource
      service={RoomService}
      label={t('ROOM.SINGLE')}
      id={id}
      validationSchema={roomSchema}>
      <Form.Layout.Field translationKey="ROOM.LABELS.ROOMTYPE">
        <Form.Input.Select
          options={roomTypeOptions}
          name="roomTypeId"
          placeholder={t('ROOM.PLACEHOLDERS.ROOMTYPE')}
        />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="ROOM.LABELS.SURFACE">
        <Form.Input.Number name="surface" placeholder={t('ROOM.PLACEHOLDERS.SURFACE')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="ROOM.LABELS.MINHEIGHT">
        <Form.Input.Number name="minHeight" placeholder={t('ROOM.PLACEHOLDERS.MINHEIGHT')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="ROOM.LABELS.MAXHEIGHT">
        <Form.Input.Number name="maxHeight" placeholder={t('ROOM.PLACEHOLDERS.MAXHEIGHT')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="ROOM.LABELS.AVGHEIGHT">
        <Form.Input.Number name="avgHeight" placeholder={t('ROOM.PLACEHOLDERS.AVGHEIGHT')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="ROOM.LABELS.PROJECT">
        <Form.Input.Select
          options={projectOptions}
          name="projectId"
          placeholder={t('ROOM.PLACEHOLDERS.PROJECT')}
        />
      </Form.Layout.Field>
    </Form.Resource>
  );
};
