import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';
import { useResource } from '@socialbrothers/hooks';

import { RoomForm } from '@Components/forms';
import { IRoom, RoomService } from '@Services/Room';

export const RoomDetailPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { data } = useResource<IRoom>(RoomService, id);

  const label = data?.id || t('ROOM.SINGLE') || '';

  return (
    <Page title={label}>
      <Card title={label}>
        <RoomForm />
      </Card>
    </Page>
  );
};
