import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Form } from '@socialbrothers/components/Containers';
import { Yup } from '@socialbrothers/utils';

import { useListInputOptions } from '@Hooks/useListInputOptions';
import { CategoryService } from '@Services/Category';
import { CustomerService } from '@Services/Customer';
import { ProjectService } from '@Services/Project';
import { UserService } from '@Services/User';

export const projectSchema = Yup.object({
  advisorId: Yup.string().required(),
  categoryId: Yup.string().required(),
  customerId: Yup.string().required(),
  files: Yup.mixed().required(),
  name: Yup.string().required(),
  notes: Yup.string(),
  quotation: Yup.mixed().required(),
  status: Yup.string().required(),
});

export const ProjectForm = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const categoryOptions = useListInputOptions(CategoryService);
  const customerOptions = useListInputOptions(CustomerService);
  const userOptions = useListInputOptions(UserService, 'fullName');

  return (
    <Form.Resource
      validationSchema={projectSchema}
      service={ProjectService}
      label={t('PROJECT.SINGLE')}
      id={id}>
      <Form.Layout.Field translationKey="PROJECT.LABELS.ADVISOR">
        <Form.Input.Select
          options={userOptions}
          name="advisorId"
          placeholder={t('PROJECT.PLACEHOLDERS.ADVISOR')}
        />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="PROJECT.LABELS.CATEGORY">
        <Form.Input.Select
          options={categoryOptions}
          name="categoryId"
          placeholder={t('PROJECT.PLACEHOLDERS.CATEGORY')}
        />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="PROJECT.LABELS.CUSTOMER">
        <Form.Input.Select
          options={customerOptions}
          name="customerId"
          placeholder={t('PROJECT.PLACEHOLDERS.CUSTOMER')}
        />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="PROJECT.LABELS.FILES">
        <Form.Input.File name="files" />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="PROJECT.LABELS.NAME">
        <Form.Input.Text name="name" placeholder={t('PROJECT.PLACEHOLDERS.NAME')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="PROJECT.LABELS.NOTES">
        <Form.Input.Text name="notes" placeholder={t('PROJECT.PLACEHOLDERS.NOTES')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="PROJECT.LABELS.QUOTATION">
        <Form.Input.Text name="quotation" placeholder={t('PROJECT.PLACEHOLDERS.QUOTATION')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="PROJECT.LABELS.STATUS">
        <Form.Input.Text name="status" placeholder={t('PROJECT.PLACEHOLDERS.STATUS')} />
      </Form.Layout.Field>
    </Form.Resource>
  );
};
