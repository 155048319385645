import { observer } from 'mobx-react-lite';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import { AppRoutes, AuthRoutes } from '@socialbrothers/routes';
import { getRoutesFromConfig } from '@socialbrothers/utils';

import { useStores } from '@Hooks/index';
import { isAdmin } from '@Utils/RouteUtils';

import { adminRoutes, dealerRoutes } from './routes';

const RootNavigator = () => {
  const { AuthStore } = useStores();

  const routes = isAdmin() ? adminRoutes : dealerRoutes;

  const allRoutes = AuthStore.isAuthenticated ? [...AppRoutes, ...routes] : AuthRoutes;

  return (
    <Router>
      <Switch>{getRoutesFromConfig(allRoutes)}</Switch>

      <Route path="/*" component={() => <Redirect to="/" />} />
    </Router>
  );
};

export default observer(RootNavigator);
