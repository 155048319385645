import { Header, Routes, Sidebar } from '@socialbrothers/constants/interfaces';
import { getRouteByKey } from '@socialbrothers/utils';

import {
  BlogDetailPage,
  BlogOverviewPage,
  CategoryDetailPage,
  CategoryOverviewPage,
  CompanyDetailPage,
  CompanyOverviewPage,
  CustomerDetailPage,
  CustomerOverviewPage,
  DashboardPage,
  GroupDetailPage,
  GroupOverviewPage,
  ProductDetailPage,
  ProductOverviewPage,
  ProjectDetailPage,
  ProjectOverviewPage,
  RoomDetailPage,
  RoomOverviewPage,
  RoomTypeDetailPage,
  RoomTypeOverviewPage,
  SurfaceDetailPage,
  SurfaceOverviewPage,
  TestimonialDetailPage,
  TestimonialOverviewPage,
  UserDetailPage,
  UserOverviewPage,
} from '@Pages/index';
import i18n from '@Root/i18n';

export const adminRoutes: Routes = [
  {
    key: 'CustomerDetailPage',
    label: i18n.t('CUSTOMER.SINGLE'),
    icon: 'user-friends',

    path: '/customers/:id',
    component: CustomerDetailPage,
    exact: true,
  },
  {
    key: 'CustomerOverviewPage',
    label: i18n.t('CUSTOMER.PLURAL'),
    icon: 'user-friends',

    path: '/customers',
    component: CustomerOverviewPage,
    exact: true,
  },
  {
    key: 'CompanyDetailPage',
    label: i18n.t('COMPANY.SINGLE'),
    icon: 'building',

    path: '/companies/:id',
    component: CompanyDetailPage,
    exact: true,
  },
  {
    key: 'CompanyOverviewPage',
    label: i18n.t('COMPANY.PLURAL'),
    icon: 'building',

    path: '/companies',
    component: CompanyOverviewPage,
    exact: true,
  },
  {
    key: 'UserDetailPage',
    label: i18n.t('USER.SINGLE'),
    icon: 'user',

    path: '/users/:id',
    component: UserDetailPage,
    exact: true,
  },
  {
    key: 'UserOverviewPage',
    label: i18n.t('USER.PLURAL'),
    icon: 'user',

    path: '/users',
    component: UserOverviewPage,
    exact: true,
  },
  {
    key: 'CustomerDetailPage',
    label: i18n.t('CUSTOMER.SINGLE'),
    icon: 'user-chart',

    path: '/customers/:id',
    component: CustomerDetailPage,
    exact: true,
  },
  {
    key: 'CustomerOverviewPage',
    label: i18n.t('CUSTOMER.PLURAL'),
    icon: 'user-chart',

    path: '/customers',
    component: CustomerOverviewPage,
    exact: true,
  },

  {
    key: 'TestimonialDetailPage',
    label: i18n.t('TESTIMONIAL.SINGLE'),
    icon: 'comment-alt-music',

    path: '/testimonials/:id',
    component: TestimonialDetailPage,
    exact: true,
  },
  {
    key: 'TestimonialOverviewPage',
    label: i18n.t('TESTIMONIAL.PLURAL'),
    icon: 'comment-alt-music',

    path: '/testimonials',
    component: TestimonialOverviewPage,
    exact: true,
  },

  {
    key: 'SurfaceDetailPage',
    label: i18n.t('SURFACE.SINGLE'),
    icon: 'draw-square',

    path: '/surfaces/:id',
    component: SurfaceDetailPage,
    exact: true,
  },
  {
    key: 'SurfaceOverviewPage',
    label: i18n.t('SURFACE.PLURAL'),
    icon: 'draw-square',

    path: '/surfaces',
    component: SurfaceOverviewPage,
    exact: true,
  },
  {
    key: 'RoomDetailPage',
    label: i18n.t('ROOM.SINGLE'),
    icon: 'door-closed',

    path: '/rooms/:id',
    component: RoomDetailPage,
    exact: true,
  },
  {
    key: 'RoomOverviewPage',
    label: i18n.t('ROOM.PLURAL'),
    icon: 'door-closed',

    path: '/rooms',
    component: RoomOverviewPage,
    exact: true,
  },
  {
    key: 'RoomTypeDetailPage',
    label: i18n.t('ROOMTYPE.SINGLE'),
    icon: 'ballot',

    path: '/roomtypes/:id',
    component: RoomTypeDetailPage,
    exact: true,
  },
  {
    key: 'RoomTypeOverviewPage',
    label: i18n.t('ROOMTYPE.PLURAL'),
    icon: 'ballot',

    path: '/roomtypes',
    component: RoomTypeOverviewPage,
    exact: true,
  },
  {
    key: 'GroupDetailPage',
    label: i18n.t('GROUP.SINGLE'),
    icon: 'folder-open',

    path: '/groups/:id',
    component: GroupDetailPage,
    exact: true,
  },
  {
    key: 'GroupOverviewPage',
    label: i18n.t('GROUP.PLURAL'),
    icon: 'folder-open',

    path: '/groups',
    component: GroupOverviewPage,
    exact: true,
  },
  {
    key: 'ProductDetailPage',
    label: i18n.t('PRODUCT.SINGLE'),
    icon: 'volume-mute',

    path: '/products/:id',
    component: ProductDetailPage,
    exact: true,
  },
  {
    key: 'ProductOverviewPage',
    label: i18n.t('PRODUCT.PLURAL'),
    icon: 'volume-mute',

    path: '/products',
    component: ProductOverviewPage,
    exact: true,
  },
  {
    key: 'CategoryDetailPage',
    label: i18n.t('CATEGORY.SINGLE'),
    icon: 'tags',

    path: '/categories/:id',
    component: CategoryDetailPage,
    exact: true,
  },
  {
    key: 'CategoryOverviewPage',
    label: i18n.t('CATEGORY.PLURAL'),
    icon: 'tags',

    path: '/categories',
    component: CategoryOverviewPage,
    exact: true,
  },
  {
    key: 'BlogDetailPage',
    label: i18n.t('BLOG.SINGLE'),
    icon: 'newspaper',

    path: '/blogs/:id',
    component: BlogDetailPage,
    exact: true,
  },
  {
    key: 'BlogOverviewPage',
    label: i18n.t('BLOG.PLURAL'),
    icon: 'newspaper',

    path: '/blogs',
    component: BlogOverviewPage,
    exact: true,
  },
  {
    key: 'ProjectDetailPage',
    label: i18n.t('PROJECT.SINGLE'),
    icon: 'cabinet-filing',

    path: '/projects/:id',
    component: ProjectDetailPage,
    exact: true,
  },
  {
    key: 'ProjectOverviewPage',
    label: i18n.t('PROJECT.PLURAL'),
    icon: 'cabinet-filing',

    path: '/projects',
    component: ProjectOverviewPage,
    exact: true,
  },
  {
    key: 'DashboardPage',
    label: 'Dashboard',
    icon: 'tachometer-alt',

    path: '/',
    component: DashboardPage,
    exact: true,
  },
];

export const dealerRoutes: Routes = [
  {
    key: 'ProjectDetailPage',
    label: i18n.t('PROJECT.SINGLE'),
    icon: 'cabinet-filing',

    path: '/projects/:id',
    component: ProjectDetailPage,
    exact: true,
  },
  {
    key: 'ProjectOverviewPage',
    label: i18n.t('PROJECT.PLURAL'),
    icon: 'cabinet-filing',

    path: '/projects',
    component: ProjectOverviewPage,
    exact: true,
  },
  {
    key: 'DashboardPage',
    label: 'Dashboard',
    icon: 'tachometer-alt',

    path: '/',
    component: DashboardPage,
    exact: true,
  },
];

export const sidebarAdmin: Sidebar = [
  {
    title: i18n.t('GLOBAL.GENERAL'),

    routes: [
      getRouteByKey(adminRoutes, 'CustomerOverviewPage'),
      getRouteByKey(adminRoutes, 'TestimonialOverviewPage'),
      getRouteByKey(adminRoutes, 'ProductOverviewPage'),
      getRouteByKey(adminRoutes, 'ProjectOverviewPage'),
      getRouteByKey(adminRoutes, 'SurfaceOverviewPage'),
      getRouteByKey(adminRoutes, 'RoomOverviewPage'),
      getRouteByKey(adminRoutes, 'RoomTypeOverviewPage'),
      getRouteByKey(adminRoutes, 'CategoryOverviewPage'),
      getRouteByKey(adminRoutes, 'CompanyOverviewPage'),
      getRouteByKey(adminRoutes, 'GroupOverviewPage'),
      getRouteByKey(adminRoutes, 'BlogOverviewPage'),
      getRouteByKey(adminRoutes, 'DashboardPage'),
      getRouteByKey(adminRoutes, 'UserOverviewPage'),
    ],
  },
];
export const sidebarDealer: Sidebar = [
  {
    title: i18n.t('GLOBAL.GENERAL'),

    routes: [
      getRouteByKey(dealerRoutes, 'ProjectOverviewPage'),
      getRouteByKey(dealerRoutes, 'DashboardPage'),
    ],
  },
];

export const header: Header = [
  {
    label: i18n.t('ACCOUNT.TITLE'),
    link: '/account',
  },
];
