import React, { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@socialbrothers/components/UI';
import { Color, YupFileExtensionStatus } from '@socialbrothers/constants';
import { getFileURL, isFileImage } from '@socialbrothers/utils';

import styles from './FileInput.module.scss';
import { FileInputProps } from './FileInput.props';

const FileInput = ({ name, onDelete, thumbnail = true, ...props }: FileInputProps) => {
  const { control, watch } = useFormContext();
  const value = watch(name);
  const { t } = useTranslation();

  const [fileName, setFileName] = useState<string>();
  const [preview, setPreview] = useState<{ path: string; status: YupFileExtensionStatus }>();

  const getPreview = async (path: string) => {
    if (thumbnail) {
      const data = await isFileImage(path);
      setPreview(data);
    }
  };

  useEffect(() => {
    if (typeof value === 'string') {
      setFileName(value);
      getPreview(getFileURL(value));
    } else if (typeof value?.name === 'string') {
      setFileName(value.name);
      getPreview(URL.createObjectURL(value));
    } else if (typeof value?.path === 'string') {
      setFileName(value.path);
      getPreview(getFileURL(value.path));
    } else {
      setFileName(undefined);
      setPreview(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onRemove = () => {
    if (document.getElementById(name))
      (document.getElementById(name) as HTMLInputElement).value = '';

    if (typeof value === 'string' && onDelete) {
      onDelete();
    }

    control.setValue(name, null);
  };

  return (
    <div className={styles.FileInput} {...props}>
      <label className={styles.FileInput__Input} htmlFor={name}>
        <Controller
          name={name}
          control={control}
          defaultValue={null}
          render={({ onChange }) => {
            const onChangleHandler = (event: ChangeEvent<HTMLInputElement>) => {
              const file = event.target.files?.[0];
              onChange(file);
            };

            return (
              <input onChange={onChangleHandler} {...props} type="file" name={name} id={name} />
            );
          }}
        />

        <div className={styles.FileInput__Name}>{fileName || '...'}</div>

        <div className={styles.FileInput__Button}>
          {fileName ? t('FORMS.FILE.LABEL_CHANGE') : t('FORMS.FILE.LABEL_CHOOSE')}
        </div>
      </label>

      {preview && (
        <div className={styles.Preview}>
          <div className={styles.Preview__Item}>
            <a
              className={styles.Preview__Link}
              href={preview.path}
              target="_blank"
              rel="noreferrer">
              {preview.status === 'ok' && (
                <img className={styles.Preview__File} src={preview.path} alt="Preview" />
              )}

              {preview.status === 'error' && (
                <Icon className={styles.Preview__File} icon="file-alt" />
              )}
            </a>

            <Button
              type="button"
              onClick={onRemove}
              className={styles.Preview__Delete}
              color={Color.DANGER}
              icon="trash-alt"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FileInput;
