import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { GroupService, IGroup } from '@Services/Group';

import styles from './GroupTable.module.scss';
import { GroupTableProps } from './GroupTable.props';

export const GroupTable = ({ className, defaultFilters }: GroupTableProps) => {
  const { t } = useTranslation();

  return (
    <Table.Resource<IGroup>
      searchable
      defaultFilters={defaultFilters}
      service={GroupService}
      className={cn(styles.GroupTable, className)}>
      <Table.Field.Text source="name" sortable label={t('GROUP.LABELS.NAME')} />

      <Table.Field.ResourceAction
        deleteConfig={(record: IGroup) => ({
          name: record.name,
        })}
        editConfig={(record: IGroup) => ({
          link: `/groups/${record.id}`,
        })}
      />
    </Table.Resource>
  );
};
