import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Form } from '@socialbrothers/components/Containers';
import { Yup } from '@socialbrothers/utils';

import { useListInputOptions } from '@Hooks/useListInputOptions';
import { CategoryService } from '@Services/Category';
import { CustomerService } from '@Services/Customer';
import { TestimonialService } from '@Services/Testimonial';

export const testimonialSchema = Yup.object({
  customerId: Yup.string().required(),
  description: Yup.string().required(),
  categoryId: Yup.string().required(),
});

export const TestimonialForm = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const categories = useListInputOptions(CategoryService);
  const customerOptions = useListInputOptions(CustomerService);

  return (
    <Form.Resource
      service={TestimonialService}
      label={t('TESTIMONIAL.SINGLE')}
      validationSchema={testimonialSchema}
      id={id}>
      <Form.Layout.Field translationKey="TESTIMONIAL.LABELS.CUSTOMERID">
        <Form.Input.Select
          name="customerId"
          options={customerOptions}
          placeholder={t('TESTIMONIAL.PLACEHOLDERS.CUSTOMERID')}
        />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="TESTIMONIAL.LABELS.DESCRIPTION">
        <Form.Input.Text
          name="description"
          placeholder={t('TESTIMONIAL.PLACEHOLDERS.DESCRIPTION')}
        />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="TESTIMONIAL.LABELS.CATEGORY">
        <Form.Input.Select
          name="categoryId"
          options={categories}
          placeholder={t('TESTIMONIAL.PLACEHOLDERS.CATEGORY')}
        />
      </Form.Layout.Field>
    </Form.Resource>
  );
};
