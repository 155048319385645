import { Api, LoginResponse } from '@socialbrothers/constants';

enum StorageKey {
  ACCESS_TOKEN = 'REACT_APP_ACCESS_TOKEN',
  TABLE_OPTIONS = 'REACT_APP_TABLE_OPTIONS',
}

type TokenStorage = {
  [K in Api]?: LoginResponse;
};

class Storage {
  setItem = (key: StorageKey, value: any) => {
    const data = JSON.stringify(value);

    localStorage.setItem(key, data);
  };

  getItem = <T>(key: StorageKey): T | null => {
    const result = localStorage.getItem(key);

    if (!result) {
      return null;
    }

    return JSON.parse(result);
  };

  setAccessToken = (api: Api, token: LoginResponse) => {
    const currentTokens = this.getAccessTokens();

    const newTokens = {
      ...currentTokens,
      [api]: token,
    };

    return this.setItem(StorageKey.ACCESS_TOKEN, newTokens);
  };

  removeAccessToken = (api: Api) => {
    const currentTokens = this.getAccessTokens();
    delete currentTokens[api];

    return this.setItem(StorageKey.ACCESS_TOKEN, currentTokens);
  };

  getAccessToken = (api: Api): LoginResponse | null => {
    const tokens = this.getAccessTokens();

    if (!tokens) {
      return null;
    }

    return tokens[api] || null;
  };

  getAccessTokens = (): TokenStorage => {
    const tokens = this.getItem<TokenStorage>(StorageKey.ACCESS_TOKEN);

    if (!tokens) {
      return {};
    }

    return tokens;
  };

  getIsAccessTokenValid = (): boolean => {
    const token = this.getAccessToken(Api.MAIN);

    if (!token) {
      return false;
    }

    return true;
  };

  getTableOptions = (tableEndpoint: string) => {
    const options: any = this.getItem(StorageKey.TABLE_OPTIONS) || {};

    return options[tableEndpoint];
  };

  setTableOptions = (tableEndpoint: string, tableOptions: any) => {
    const options: any = this.getItem(StorageKey.TABLE_OPTIONS) || {};

    return this.setItem(StorageKey.TABLE_OPTIONS, {
      ...options,
      [tableEndpoint]: tableOptions,
    });
  };
}

export default new Storage();
